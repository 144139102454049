const HelpData = [
    {
        route: ['price-table.new', 'price-table.edit'],
        shortcuts: [
            {keys: ['S'], description: 'Salva a tabela de preço'},
        ],
        help_items:
            [
                {
                    title: 'Ajuda geral',
                    text: 'Tela para calculo de ajuste de precificaçao dos produtos, por canal de venda'
                },
                {
                    title: 'Adicionado produtos/Categorias',
                    text: 'Escolha uma categoria , ou os produtos que deseja adicionar ao ajuste, e clique em adicionar.'
                }
            ]
    }
]

export default HelpData
