<template>
  <div>
    <b-form-group
      :label="label"
      :label-for="name"
      :class="{'disabled': readonly || disabled}"
    >
      <validation-provider
        #default="{ errors }"
        :name="label"
        :vid="name"
        :rules="rules"
      >
        <b-form-input
          :id="name"
          v-model="inputValue"
          v-mask="mask ? mask : null"
          autocomplete="false"
          :type="type"
          :debounce="delay"
          :state="errors.length > 0 ? false:null"
          :placeholder="placeholder"
          :readonly="readonly"
          :disabled="disabled"
          v-bind="$attrs"
          v-on="$listeners"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
      <item-help v-if="help" :help="help" class="float-right position-relative l-0"/>
    </b-form-group>

  </div>
</template>

<script>

import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import {
  required, min, minValue, notEmpty,
} from '@validations'
import { applyMask as _mask } from '@/helpers/mask'
import ItemHelp from "@/components/i2/forms/elements/ItemHelp.vue"

export default {
  name: 'I2FormInput',
  components: {
    ItemHelp,
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  directives: {
    mask: _mask,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    delay: {
      type: Number,
      default: 0,
    },
    help: {
      type: String,
      default: '',
      required: false,
    }
  },
  data: () => ({
    inputValue: '',
    errors: [],
    required,
    min,
    minValue,
    notEmpty,
  }),
  watch: {
    value(data) {
      this.inputValue = data
    },
    inputValue(val) {
      this.$emit('input', val)
    },
  },
  created() {
    this.inputValue = this.value
  },

}
</script>

<style scoped>

</style>
