const defaultCategory = [
  {
    path: '/default-categories',
    name: 'default-categories.list',
    component: () => import('@/views/defaultcategory/DefaultCategoryList.vue'),
    meta: {
      env:['production'],
      roles:[5],
    }
  },
]

export default defaultCategory
