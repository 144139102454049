const shippingCompany = [
    {
        path: '/shipping-companies',
        name: 'shipping-companies.list',
        component: () => import('@/views/shippingcompany/ShippingCompanyList.vue'),
        meta: {
            env:['production'],
            roles: [5],
            pageTitle: 'Empresas de Entrega',
            breadcrumb: [
                {
                    text: 'Lista',
                    active: false,
                    to: '/shipping-companies/',
                },
                {
                    text: 'Novo',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/shipping-companies/:uuid/tables',
        name: 'shipping-companies-table.list',
        component: () => import('@/views/shippingcompany/ShippingCompanyTableList.vue'),
        meta: {
            roles: [5],
            env:['production'],
            pageTitle: 'Tabelas',
            breadcrumb: [
                {
                    text: 'Lista',
                    active: false,
                    to: '/shipping-companies/',
                },
                {
                    text: 'Novo',
                    active: true,
                },
            ],
        },
    },
]

export default shippingCompany
