<template>
  <div>
    <span v-b-tooltip.hover :title="help">
      <b-icon-question-circle class="help-icon"/>
    </span>
  </div>
</template>

<script>

import {BTooltip, VBTooltip} from 'bootstrap-vue'

export default {
  name: 'ItemHelp',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    help: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.help-icon {
  margin-left: 5px;
  cursor: pointer;
  color: #5a6268;
}

.help-icon:hover {
  color: #343a40;
}
</style>
